import { FunctionComponent, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { IDeviceInfo, useUI } from '@components/ui/context'
import axios from '@services/axiosInterceptorInstance'
import {
  EmptyGuid,
  NEXT_API_NOTIFY_ME_ENDPOINT,
} from '@components/utils/constants'
import { checkIsPhoneValid, getCurrentPage } from '@framework/utils/app-util'
import { GA_EVENT } from 'hooks/ga_event.modal'
import { recordGA4Event } from '@components/services/analytics/ga4'
import { useMoEngageEvent } from 'hooks/useMoengageEvent'
import { MO_ENGAGE_EVENT } from 'hooks/moengage_event.modal'
import { Cross } from '@components/icons'

declare global {
  interface Window {
    Moengage: any
  }
}

interface IProps {
  deviceInfo: IDeviceInfo
}

const NotifyUserPopup: FunctionComponent<IProps> = ({ deviceInfo }) => {
  const { isMobile } = deviceInfo
  const [email, setPhoneNo] = useState('')
  const [isPostedMessage, setIsPosted] = useState('')
  const trackMoEngageEvent = useMoEngageEvent()

  const cancelButtonRef = useRef(null)

  const { closeNotifyUser, productId, notifyProduct: product, user } = useUI()

  const isValidpostphoneNo = checkIsPhoneValid(email)

  const getlineItemSizeWithoutSlug = (product: any) =>
    product?.variantProducts
      ?.filter((vp: any) => vp?.stockCode === product?.stockCode)[0]
      ?.attributes.filter((va: any) => va.fieldCode === 'clothing.size')[0]
      ?.fieldValue
  const currentPage = getCurrentPage()

  const handleModal = () => {
    const postphoneNo = async () => {
      const result = await axios.post(
        `${NEXT_API_NOTIFY_ME_ENDPOINT}?email=${email}&mobileNo=${email}&productId=${productId}`
      )
      if (result.data) {
        setIsPosted(
          'Your request submit successfully!! We will notify you once product is back in stock. Thank you for your patience.'
        )
        setTimeout(() => {
          closeNotifyUser()
          const elementnoti: any = document.getElementsByTagName('html')[0]
          elementnoti.classList.add('overlow-y-auto--notify')
        }, 3500)
      } else {
        setIsPosted('Woops! Something went wrong')
        setTimeout(() => {
          closeNotifyUser()
          const elementnoti: any = document.getElementsByTagName('html')[0]
          elementnoti.classList.add('overlow-y-auto--notify')
        }, 3500)
      }
    }
    if (email && isValidpostphoneNo) postphoneNo()

    if (email.length === 10) {
      recordGA4Event(window, GA_EVENT.NOTIFY_ME, {
        current_page: currentPage,
        loggedin_status: !!(user?.userId && user?.userId !== EmptyGuid),
      })
    }

    recordGA4Event(window, GA_EVENT.NOTIFY_CLICK, {
      current_page: currentPage,
      button: 'submit',
      device: isMobile ? 'mobile web' : 'desktop web',
      loggedin_status: !!(user?.userId && user?.userId !== EmptyGuid),
    })

    trackMoEngageEvent(
      MO_ENGAGE_EVENT.NOTIFY_CLICK,
      {
        URL: window?.location?.href,
        notify_click: 'Click',
        notify_submit: true,
        Product_name: product?.name,
        Product_category: product?.mappedCategories?.[1]?.categoryName,
        Product_category_2: product?.mappedCategories?.[2]?.categoryName,
        Product_size: getlineItemSizeWithoutSlug(product),
        Phone_no : email,
        color: product?.variantGroupCode,
      },
      window
    )
  }

  const enableBodyScrollto = () => {
    const element: any = document.getElementsByTagName('html')[0]
    element.classList.add('overlow-y-auto--notify')
    recordGA4Event(window, GA_EVENT.NOTIFY_CLICK, {
      current_page: currentPage,
      button: 'close',
      device: isMobile ? 'mobile web' : 'desktop web',
      loggedin_status: !!(user?.userId && user?.userId !== EmptyGuid),
    })
  }

  return (
    <>
      <Transition.Root show={true} as={'div'}>
        <Dialog
          as="div"
          className="relative z-99"
          initialFocus={cancelButtonRef}
          onClose={() => {
            closeNotifyUser()
            enableBodyScrollto()
          }}
        >
          <div className="fixed inset-0 left-0 bg-orange-900/20" />
          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="fixed inset-y-0 right-0 flex max-w-full pointer-events-none">
                <Transition.Child
                  as={'div'}
                  enter="transform transition ease-in-out duration-500 sm:duration-400"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-400"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="w-screen max-w-md pointer-events-auto h-full">
                    <div className="relative z-50 flex flex-col h-full bg-white shadow-xl">
                      <div className="z-10 px-4 py-6 sm:pb-2 sm:px-6 left-1 top-1">
                        <div className="flex justify-between">
                          <h3 className="block text-xl font-semibold text-gray-dark dark:text-black sm:hidden">
                            Notify Me on Whatsapp
                          </h3>
                          <button
                            type="button"
                            className="mr-2 text-black rounded-md outline-none hover:text-gray-500"
                            onClick={() => {
                              closeNotifyUser()
                              enableBodyScrollto()
                            }}
                          >
                            <span className="sr-only">Close panel</span>
                            <Cross
                              className="relative w-8 h-8 top-1"
                              aria-hidden="true"
                            />
                          </button>
                        </div>
                      </div>
                      <div className="hidden px-4 py-1 sm:block sm:px-6">
                        <h3 className="text-xl font-semibold text-gray-dark dark:text-black">
                          Notify Me on Whatsapp
                        </h3>
                      </div>
                      <div className="flex flex-col w-full px-6">
                        {isPostedMessage ? (
                          <div className="flex flex-1 w-full mt-6 text-center">
                            <h3 className="text-xl font-medium text-black">
                              {isPostedMessage}
                            </h3>
                          </div>
                        ) : (
                          <form className="flex pt-0 pb-5 mt-2 sm:max-w-md">
                            <label htmlFor="email-address" className="sr-only">
                              Mobile Number
                            </label>
                            <input
                              id="email-address"
                              type="tel"
                              autoComplete="email"
                              onChange={(e) =>
                                setPhoneNo(e.currentTarget.value)
                              }
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault()
                                }
                              }}
                              placeholder="Mobile Number"
                              minLength={10}
                              maxLength={10}
                              required
                              className="w-full min-w-0 px-4 py-4 text-gray-900 placeholder-gray-500 bg-gray-200 border border-gray-300 appearance-none focus:outline-none focus:border-black focus:ring-1 focus:ring-black"
                            />
                          </form>
                        )}
                      </div>
                      {isPostedMessage ? null : (
                        <>
                          <div className="flex flex-col w-full px-6">
                            <button
                              type="button"
                              disabled={!isValidpostphoneNo}
                              style={
                                !isValidpostphoneNo
                                  ? { opacity: '20%', pointerEvents: 'none' }
                                  : {}
                              }
                              className="inline-flex justify-center w-full px-4 py-4 font-medium text-white bg-black border border-transparent hover:bg-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black"
                              onClick={() => handleModal()}
                            >
                              Submit
                            </button>
                          </div>
                          <div className="flex flex-col px-6 mt-4">
                            <p className="text-sm font-light text-primary">
                              Enter your phone number to stay in the know when
                              the product is back in stock
                            </p>
                          </div>
                        </>
                      )}
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  )
}

export default NotifyUserPopup
